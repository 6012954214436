const PermitService = {
    
    setUserTypeParkingLots(userTypeUuid, userTypeParkingLots) {
        //window.console.log(userTypeUuid)
        //window.console.log(userTypeParkingLots)
        let selectedParkingLots = []
        for(const property in userTypeParkingLots) {
            if(property === userTypeUuid) {
                //window.console.log(userTypeParkingLots[property])
                let parkingLotsArray = userTypeParkingLots[property]
                parkingLotsArray.forEach(parkingLot => {
                    //window.console.log(parkingLot)
                    selectedParkingLots.push(parkingLot)
                    //window.console.log(selectedParkingLots)
                })
            }
        }
        
        //window.console.log(selectedParkingLots)
        return selectedParkingLots;
    },

    setUserTypePillars(userTypeUuid, userTypePillars) {
        let selectedPillars = []
        for(const property in userTypePillars) {
            if(property === userTypeUuid) {
                let pillarsArray = userTypePillars[property];
                pillarsArray.forEach(pillar => {
                    selectedPillars.push(pillar)
                    //window.console.log(pillar)
                })
            }
        }

        //window.console.log(selectedPillars);
        return selectedPillars;
    },

    parkingPermitTypeUuid() {
        return '62d59f1c-5a2b-463a-8c5e-3f4e36776718'
    },

    promenadaPermitTypeUuid() {
        return '46e27f03-9003-45a8-a7ff-bb38406a3b65'
    }


}

export default PermitService 